<template>
  <b-card title="Listar Clientes">
    <listagemClientes />
  </b-card>
</template>

<script>

import {
  BCard,
} from 'bootstrap-vue'

import listagemClientes from '../Listagens/Clientes/ListagemClientes.vue'

export default {
  components: {
    BCard,
    listagemClientes,
  },
}

</script>
